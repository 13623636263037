<template>
  <div>
    <div class="faq-section">
      <div class="container">
        <div class="text-center pt-4">
          <p
            class="font-medium text-white fs-2 mb-1 animation"
            data-animation="fadeIn"
          >
            Frequently Asked Questions
          </p>
          <p class="font-regular text-white animation" data-animation="fadeIn">
            Here are the answers of the most common questions we asked from our
            valued customers.
          </p>
        </div>
      </div>
      <div>
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8 col-11">
            <div
              class="card shadow p-4 mb-4 rounded-0 animation"
              data-animation="fadeIn"
            >
              <div class="card-body">
                <div class="accordion" id="question_1">
                  <div class="accordion-item mb-3 border-0 rounded">
                    <h2
                      class="accordion-header border-bottom pb-2 border-secondary"
                    >
                      <button
                        class="accordion-button bg-dark bg-gradient border-0 rounded text-white font-bold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse_1"
                        aria-expanded="true"
                        aria-controls="collapse_1"
                      >
                        <span>How do I get started with your services?</span>
                      </button>
                    </h2>
                    <div
                      id="collapse_1"
                      class="accordion-collapse collapse"
                      data-bs-parent="#question_1"
                    >
                      <div class="accordion-body">
                        To get started, simply navigate to the service you're
                        interested in on our website and click on the "Let's Start" or "Contact Us" button. You can also schedule a
                        free consultation to discuss your needs and explore the
                        right service package for you.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion" id="question_2">
                  <div class="accordion-item mb-3 border-0 rounded">
                    <h2
                      class="accordion-header border-bottom pb-2 border-secondary"
                    >
                      <button
                        class="accordion-button bg-dark bg-gradient border-0 rounded text-white font-bold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse_2"
                        aria-expanded="true"
                        aria-controls="collapse_2"
                      >
                        <span
                          >Are your services suitable for beginners, or do I
                          need prior experience in e-commerce?</span
                        >
                      </button>
                    </h2>
                    <div
                      id="collapse_2"
                      class="accordion-collapse collapse"
                      data-bs-parent="#question_2"
                    >
                      <div class="accordion-body">
                        Our services are designed to cater to a wide range of
                        clients, from beginners to experienced e-commerce
                        entrepreneurs. We provide guidance and support at all
                        levels to ensure your success, whether you're just
                        starting out or looking to scale your existing business.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion" id="question_3">
                  <div class="accordion-item mb-3 border-0 rounded">
                    <h2
                      class="accordion-header border-bottom pb-2 border-secondary"
                    >
                      <button
                        class="accordion-button bg-dark bg-gradient border-0 rounded text-white font-bold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse_3"
                        aria-expanded="true"
                        aria-controls="collapse_3"
                      >
                        <span
                          >What sets your e-commerce services apart from
                          others?</span
                        >
                      </button>
                    </h2>
                    <div
                      id="collapse_3"
                      class="accordion-collapse collapse"
                      data-bs-parent="#question_3"
                    >
                      <div class="accordion-body">
                        What makes us stand out is our tailored approach. We
                        understand that every client is unique, so we customize
                        our services to match your specific goals and needs. Our
                        dedicated team of experts is committed to your success.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion" id="question_4">
                  <div class="accordion-item mb-3 border-0 rounded">
                    <h2
                      class="accordion-header border-bottom pb-2 border-secondary"
                    >
                      <button
                        class="accordion-button bg-dark bg-gradient border-0 rounded text-white font-bold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse_4"
                        aria-expanded="true"
                        aria-controls="collapse_4"
                      >
                        <span
                          >How can I contact your support team if I have more
                          questions?</span
                        >
                      </button>
                    </h2>
                    <div
                      id="collapse_4"
                      class="accordion-collapse collapse"
                      data-bs-parent="#question_4"
                    >
                      <div class="accordion-body">
                        You can reach our support team by clicking on the
                        "Contact Us" button on our website. We're here to answer
                        any additional questions you may have and provide you
                        with the assistance you need to embark on your
                        e-commerce journey.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer bg-body-secondary">
      <div class="footer-top" style="padding-top: 100px">
        <div>
          <div class="row" style="margin: 0px">
            <div class="col-md-4 col-sm-12 col-lg-4 logo-bg">
              <div
                class="widget widget_contact_info"
                style="padding: 80px 50px 30px 50px; margin-top: 100px"
              >
                <div class="widget-logo" style="margin: 0px">
                  <img
                    alt="Blinkers"
                    class="img-fluid"
                    width="250px"
                    src="@/assets/img/footer/logo.png"
                  />
                </div>
                <div class="info-content">
                  <h2 class="text-white font-medium">We Commit!</h2>
                  <h2 class="text-white font-medium">We Research!</h2>
                  <h2 class="text-white font-medium">We Deliver!</h2>
                </div>
              </div>
            </div>
            <div class="col-md-8 col-sm-12 col-lg-8 pt-4">
              <div class="row align-items-baseline">
                <div class="col-lg-4 col-sm-6">
                  <div>
                    <div>
                      <h2 class="font-medium">Quick Links</h2>
                    </div>
                    <ul class="menu p-0 font-regular">
                      <li @click="toTop()">
                        <router-link
                          to="/about"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          About</router-link
                        >
                      </li>
                      <li @click="toTop()">
                        <router-link
                          to="/portfolio"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          Portfolio</router-link
                        >
                      </li>
                      <li @click="toTop()">
                        <router-link
                          to="/contact"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          Contact</router-link
                        >
                      </li>
                    </ul>
                    <div>
                        <h2 class="font-medium">Contact</h2>
                    </div>
                    <div>
                      <p class="font-regular mb-1">
                        Email: 
                      <a href="mailto:info@blinkersmarketing.com" class="text-dark"
                        >info@blinkersmarketing.com</a
                      >
                    </p>
                    <p class="font-regular mb-1">
                      Phone: 
                      <a href="tel:(843) 400 0945" class="text-dark">(843) 400 0945</a>
                    </p>
                    <div class="font-regular d-flex align-items-start gap-1">
                      <p>Address:</p> <p>Grenada Falls Drive, <br>Houston, TX 77095</p>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                  <div>
                    <div>
                      <h2 class="font-medium">SERVICES</h2>
                    </div>
                    <ul class="menu p-0 font-regular">
                      <li @click="toTop()">
                        <router-link
                          to="/service/amazon-private-label"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          Amazon Private Label</router-link
                        >
                      </li>
                      <li @click="toTop()">
                        <router-link
                          to="/service/amazon-online-arbitrage"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          Amazon Online Arbitrage</router-link
                        >
                      </li>
                      <li @click="toTop()">
                        <router-link
                          to="/service/walmart-wfs"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          Walmart WFS</router-link
                        >
                      </li>
                      <li @click="toTop()">
                        <router-link
                          to="/service/walmart-dropshipping"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          Walmart 2-Step Dropshipping</router-link
                        >
                      </li>
                      <li @click="toTop()">
                        <router-link
                          to="/service/etsy-dropshipping"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          Etsy Dropshipping</router-link
                        >
                      </li>
                      <li @click="toTop()">
                        <router-link
                          to="/service/etsy-digital-store"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          Etsy Digital Store</router-link
                        >
                      </li>
                      <li @click="toTop()">
                        <router-link
                          to="/service/shopify-dropshipping"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          Shopify Dropshipping</router-link
                        >
                      </li>
                      <li @click="toTop()">
                        <router-link
                          to="/service/amazon-automation"
                          class="text-decoration-none text-dark"
                          ><span class="me-3"
                            ><i class="bi bi-caret-right-fill"></i
                          ></span>
                          Amazon Automation</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                  <div class="px-2 py-4">
                    <div>
                      <h2 class="font-medium">Stay in Touch</h2>
                    </div>
                    <p class="text-capitalize font-regular">
                      our social links for more <br />
                      inspirational content.
                    </p>
                    <div class="social">
                      <a href="https://www.facebook.com/blinkersmkt" target="_blank"
                        ><i class="text-dark bi bi-facebook fs-4"></i></a
                      ><a href="https://www.instagram.com/blinkersmkt" target="_blank"
                        ><i class="text-dark bi bi-instagram fs-4 mx-3"></i></a
                      ><a href="https://www.linkedin.com/company/blinkersus" target="_blank"
                        ><i class="text-dark bi bi-linkedin fs-4"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row py-2 mb-2">
                <div class="col-md-4 font-regular">
                  <!-- <a href="" class="text-info">Privacy </a>
                  |
                  <a href="" class="text-info">Disclaimer </a>
                  |
                  <a href="" class="text-info"> Terms &amp; Condition </a> -->
                  <p>Copyright &copy; 2023 All Rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.logo-bg {
  background: url("../../assets/img/footer/footer-shape.png");
  background-size: 93% 100%;
  margin-top: -100px;
  background-repeat: no-repeat;
}
.menu {
  list-style: none;
  li {
    margin-bottom: 6px;
  }
  .bi-caret-right-fill {
    font-size: 13px;
  }
}
.faq-section {
  background-image: url("../../../public/services/service-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.accordion-button::after {
  content: "\F22A" !important;
  font-family: "bootstrap-icons";
  background-image: none !important;
}
.font-bold {
  font-family: "Ubuntu-Bold";
}
.font-medium {
  font-family: "Ubuntu-Medium";
}
.font-regular {
  font-family: "Ubuntu-Regular";
}
.font-light {
  font-family: "Ubuntu-Light";
}
</style>
