import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import( '../views/Landing.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/About.vue')
  },
  {
    path: '/service/:id',
    name: 'services',
    component: () => import( '../views/services.vue')
  },
  {
    path: '/service/amazon-automation',
    name: 'automation',
    component: () => import( '../components/amazonAutomation.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import( '../views/Portfolio.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/Contact.vue')
  },
  {
    path: '/case-studies/:id',
    name: 'case-studies',
    component: () => import( '../views/CaseStudies.vue')
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import( '../views/404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
