<template>
  <div id="app">
    <!-- Loader -->
    <!-- <div class="loader-overlay" v-if="loading">
      <div class="spinner-grow text-dark" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow text-dark mx-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow text-dark" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div> -->
    <Navbar />
    <router-view />
    <Footer />
    <button
      v-if="showButton"
      @click="scrollToTop()"
      id="toTop"
      title="Go to top"
    >
      <i class="bi bi-chevron-up fs-5"></i>
    </button>
    <div
      v-if="notSubscribed"
      class="modal fade"
      id="popupModal"
      tabindex="-1"
      aria-labelledby="popupModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body position-relative">
            <button
              type="button"
              class="btn-close position-absolute bg-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="row align-items-center">
              <div class="col-lg-6 text-center">
                <h3 class="font-bold">
                  Want 10 Handful Amazon Successful 
                  <span class="text-primary">Seller Templates</span> for Free?
                </h3>
                <div class="modal-footer border-0">
                  <div class="mb-3 text-start w-100">
                    <label for="email" class="form-label font-regular"
                      >Email address</label
                    >
                    <input
                      type="email"
                      class="form-control bg-secondary bg-opacity-10"
                      id="email"
                      placeholder="Your Email"
                      v-model="userEmail"
                    />
                    <small v-if="error" class="error text-danger">{{
                      error
                    }}</small>
                  </div>
                  <button
                    v-if="!sending"
                    class="btn btn-primary w-100 font-bold text-white"
                    @click="submitForm()"
                  >
                    Claim your free Templates now!
                  </button>
                  <button
                    v-if="sending"
                    class="btn btn-primary w-100 font-bold text-white disabled"
                  >
                    Please Wait...
                  </button>
                </div>
                <small v-if="submitError" class="error text-danger"
                  >Something went wrong. Please try again</small
                >
              </div>
              <div class="col-lg-6">
                <img
                  src="/templates.png"
                  alt="Seller Template"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Api from "./assets/js/api.js";
import Swal from "sweetalert2";
import Navbar from "./components/layouts/navbar.vue";
import Footer from "./components/layouts/footer.vue";
import { Modal } from "bootstrap";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      showButton: false,
      userEmail: "",
      error: "",
      sending: false,
      submitError: false,
      notSubscribed: true,
    };
  },
  mounted() {
    const isSubscribed = localStorage.getItem("subscribed");
    if (!isSubscribed) {
      this.notSubscribed = true;
      setTimeout(() => {
        const popupModal = new Modal(document.getElementById("popupModal"));
        popupModal.show();
      }, 1000);
    } else {
      this.notSubscribed = false;
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showButton = window.scrollY > 200;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    isValidEmail(email) {
      const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
      return emailRegex.test(email);
    },
    submitForm() {
      if (this.isValidEmail(this.userEmail)) {
        this.sending = true;
        this.error = "";
        axios
          .post(`${Api}/free-template`, { userEmail: this.userEmail })
          .then((response) => {
            localStorage.setItem("subscribed", "true");
            var modal = Modal.getInstance(
              document.getElementById("popupModal")
            );
            modal.hide();
            Swal.fire(
              "Templates Sent!",
              "We've sent your free Amazon Seller Templates. Please check your inbox.",
              "success"
            );
            this.userEmail = "";
            this.sending = false;
          })
          .catch((error) => {
            this.sending = false;
            this.submitError = true;
            console.error("Error:", error);
          });
      } else {
        this.error = "Invalid email address";
        this.sending = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  scroll-behavior: smooth;
  overflow-x: hidden;
  #toTop {
    position: fixed;
    bottom: 100px;
    right: 30px;
    z-index: 99;
    font-size: 10px;
    border: none;
    outline: none;
    background-color: black;
    color: white;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    transition: all 0.6s;
  }
  .loader-overlay {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    80% {
      opacity: 1;
      transform: none;
    }
  }
}
.btn-close {
  right: -3%;
  top: -4%;
}
.font-bold {
  font-family: "Ubuntu-Bold";
}
.font-medium {
  font-family: "Ubuntu-Medium";
}
.font-regular {
  font-family: "Ubuntu-Regular";
}
.font-light {
  font-family: "Ubuntu-Light";
}
@media screen and (max-width: 992px) {
  .btn-close {
    top: 0;
    right: 0;
  }
}
</style>
