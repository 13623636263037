import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    "services": [
      {
        "id": "amazon-private-label",
        "highlighted_title": "Amazon Private Label Service",
        "title": "Welcome to Our",
        "subheading": "Unlock the secrets to dominating the world's largest online marketplace – are you up for the challenge? Look no further! Our Amazon Private Label service is designed to help you leverage the immense reach and potential of Amazon while building a brand that stands out from the competition.",
        "background_image": "/bg.png",
        "service_heading": "Our Amazon Private Label Services",
        "service_subheading": "We offer a comprehensive range of services to support you at every stage of your private label journey",
        "service_img": "/services/private_label.jpg",
        "our_services": [
          {
            "heading": "Product Research",
            "desc": "Identifying profitable product opportunities that align with your brand and market trends"
          },
          {
            "heading": "Sourcing and Manufacturing",
            "desc": "Sourcing high-quality products and arranging manufacturing, ensuring consistency and quality."
          },
          {
            "heading": "Branding and Packaging",
            "desc": "Crafting a compelling brand identity and designing eye-catching packaging."
          },
          {
            "heading": "Amazon Listing Optimization",
            "desc": "Creating persuasive product listings that drive traffic and conversions"
          },
          {
            "heading": "Inventory Management",
            "desc": "Efficiently manage your inventory to prevent stockouts and overstock situations."
          },
          {
            "heading": "Marketing and Promotion",
            "desc": "Implementing strategies to boost your products' visibility and sales."
          },
          {
            "heading": "Customer Support",
            "desc": "Providing top-notch customer support to enhance customer satisfaction and loyalty."
          }
        ],
        "why_us_heading": "Why Choose Us?",
        "choose_us": [
          {
            "para": "With years of experience in Amazon Private Labeling, we have a proven track record of helping businesses succeed on the Amazon platform. Our dedicated team of experts will work closely with you to turn your vision into reality."
          },
          {
            "para": "Ready to take your Amazon business to the next level with Private Labeling? Contact us today to get started!"
          }
        ],
        "how_it_works": [
          {
            "icon": "bi bi-telephone",
            "heading": "Schedule a Free Consultation",
            "text": "We’ll answer any questions you have and, when you’re ready to proceed, you can choose the right package for your needs."
          },
          {
            "icon": "bi bi-lightbulb",
            "heading": "Strategy Development",
            "text": "We'll work closely with you to develop a tailored strategy for your private label brand. This includes market research, product selection, and branding strategy."
          },
          {
            "icon": "bi bi-tools",
            "heading": "Implementation Support",
            "text": "Once the strategy is in place, we provide hands-on support as you implement your private label brand on Amazon. From product listings to optimization, we're there every step of the way."
          },
          {
            "icon": "bi bi-person-check",
            "heading": "Review",
            "text": "At this stage, we prioritize your satisfaction. We invite you to assess our work and share your valuable feedback. Your input guides our final revisions."
          }
        ],
        "card_text": "We have helped clients create thriving private label brands on Amazon, delivering impressive sales results."
      },
      {
        "id": "amazon-online-arbitrage",
        "highlighted_title": "Amazon Online Arbitrage",
        "title": "Unlock Profitable Deals with",
        "subheading": "Are you ready to master the art of finding hidden treasures and turning them into profit on Amazon? Welcome to the world of Amazon Online Arbitrage, where we transform online shopping into a lucrative business strategy.",
        "background_image": "/bg.png",
        "service_heading": "Our Amazon Online Arbitrage Services",
        "service_subheading": "Your Profit-Driven Partner",
        "service_img": "/services/arbitrage.jpg",
        "our_services": [
          {
            "heading": "Deal Hunters at Your Service",
            "desc": "Our experts scour the web to find deals that others overlook."
          },
          {
            "heading": "Risk Mitigation",
            "desc": "We evaluate deals for you, ensuring they meet your criteria for profit and success."
          },
          {
            "heading": "Sourcing Mastery",
            "desc": "Secure profitable inventory with our sourcing strategies honed through experience."
          },
          {
            "heading": "Optimize for Amazon",
            "desc": "We handle the logistics, making sure your products are Amazon-ready."
          },
          {
            "heading": "Competitive Edge",
            "desc": "Stay ahead of the competition with our insights and strategies."
          },
          {
            "heading": "Strategic Growth",
            "desc": "We help you plan and scale your arbitrage business for long-term success."
          }
        ],
        "why_us_heading": "Why Us? Your Trusted Partner in Amazon Arbitrage",
        "choose_us": [
          {
            "para": "We're not just about deals; we're about your success. Our team of Amazon arbitrage aficionados is committed to helping you navigate the ever-changing marketplace."
          },
          {
            "para": "Ready to explore the world of Amazon Online Arbitrage and uncover untapped profits? It all starts with a single click—contact us now!"
          }
        ],
        "how_it_works": [
          {
              "icon": "bi bi-telephone",
              "heading": "Schedule a Free Consultation",
              "text": "Let's start with a free consultation to understand your objectives and answer your questions."
          },
          {
              "icon": "bi bi-search",
              "heading": "Sourcing Guidance",
              "text": "We guide you through the process of sourcing profitable products online."
          },
          {
              "icon": "bi bi-basket2",
              "heading": "Arbitrage Execution",
              "text": "We assist you in executing your online arbitrage plan for maximum profit."
          },
          {
              "icon": "bi bi-person-check",
              "heading": "Review",
              "text": "Performance is vital. We encourage you to evaluate our efforts and provide feedback. Your insights drive our final adjustments for optimal results."
          }
      ],
        "card_text": "Our clients have achieved substantial success with online arbitrage on Amazon, generating impressive monthly sales."
      },
      {
        "id": "walmart-wfs",
        "highlighted_title": "Walmart WFS",
        "title": "Experience Effortless Growth with",
        "subheading": "Ready to level up your e-commerce game? Welcome to the world of Walmart Fulfillment Services (WFS), where we turn fulfillment into your strategic advantage.",
        "background_image": "/bg.png",
        "service_heading": "Our Walmart WFS Services",
        "service_subheading": "Your Fulfillment Partner",
        "service_img": "/services/walmart_wfs.jpg",
        "our_services": [
          {
            "heading": "Worry-Free Warehousing",
            "desc": "Secure, efficient storage for your products in Walmart's trusted facilities."
          },
          {
            "heading": "Expert Fulfillment",
            "desc": "Our experienced team ensures accurate, on-time order processing."
          },
          {
            "heading": "Shipping Mastery",
            "desc": "We'll get your products to customers quickly, exceeding expectations."
          },
          {
            "heading": "Inventory Intelligence",
            "desc": "Stay in control with real-time inventory management and reporting."
          },
          {
            "heading": "Prime Customer Service",
            "desc": "Our support team is here to ensure your customers are happy."
          },
          {
            "heading": "Growth Strategies",
            "desc": "Unlock growth potential with our strategic guidance."
          }
        ],
        "why_us_heading": "Why Us? Your Success, Our Priority",
        "choose_us": [
          {
            "para": "We're not just a service; we're your partner in success. Our Walmart WFS experts are dedicated to helping you navigate the e-commerce landscape and achieve your business goals."
          },
          {
            "para": "Ready to streamline your business with Walmart Fulfillment Services? It all begins with a single step—contact us now!"
          }
        ],
        "how_it_works": [
          {
            "icon": "bi bi-telephone",
            "heading": "Schedule a Free Consultation",
            "text": "We’ll answer any questions you have and, when you’re ready to proceed, you can choose the right package for your needs."
          },
          {
            "icon": "bi bi-gear-wide",
            "heading": "Integration Setup",
            "text": "After our initial discussion, we help you set up and integrate Walmart Fulfillment Services (WFS) into your operations seamlessly."
          },
          {
            "icon": "bi bi-briefcase",
            "heading": "Fulfillment Management",
            "text": "We provide ongoing support for managing your fulfillment operations through WFS, ensuring orders are processed efficiently and customers are satisfied."
          },
          {
            "icon": "bi bi-person-check",
            "heading": "Review",
            "text": "We value your feedback. You'll have the opportunity to evaluate the performance of your Walmart Fulfillment Services. Your insights help us fine-tune for excellence."
          }
        ],
        "card_text": "Elevate your business with Walmart Fulfillment Services (WFS). We optimize your operations for peak performance, ensuring your success is nothing short of exceptional."
      },
      {
        "id": "walmart-dropshipping",
        "highlighted_title": "Walmart 2-Step Dropshipping",
        "title": "Elevate Your E-Commerce with",
        "subheading": "Ready to supercharge your online store? Welcome to the world of Walmart 2-Step Dropshipping, where we simplify sourcing and amplify your product offerings.",
        "background_image": "/bg.png",
        "service_heading": "Our Walmart 2-Step Dropshipping Services",
        "service_subheading": "Your Growth Partner",
        "service_img": "/services/walmart_dropshipping.jpg",
        "our_services": [
          {
            "heading": "Product Curation",
            "desc": "We identify high-demand, trending products from Walmart's vast selection."
          },
          {
            "heading": "Seamless Integration",
            "desc": "We streamline the 2-step dropshipping process for your store."
          },
          {
            "heading": "Optimized Listings",
            "desc": "Elevate your listings with compelling descriptions and images."
          },
          {
            "heading": "Inventory Management",
            "desc": "Stay in control with real-time inventory tracking."
          },
          {
            "heading": "Fulfillment Expertise",
            "desc": "Enjoy hassle-free order fulfillment, ensuring happy customers."
          },
          {
            "heading": "Growth Strategies",
            "desc": "We provide strategies to boost your sales and expand your market reach."
          }
        ],
        "why_us_heading": "Why Us? Your Trusted Partner in Dropshipping",
        "choose_us": [
          {
            "para": "We're not just a service; we're your growth catalyst. Our team of experts is dedicated to helping you thrive in the competitive e-commerce landscape."
          },
          {
            "para": "We're not just a service; we're your growth catalyst. Our team of experts is dedicated to helping you thrive in the competitive e-commerce landscape."
          }
        ],
        "how_it_works": [
          {
            "icon": "bi bi-telephone",
            "heading": "Schedule a Free Consultation",
            "text": "We’ll answer any questions you have and, when you’re ready to proceed, you can choose the right package for your needs."
          },
          {
            "icon": "bi bi-people",
            "heading": "Supplier Selection",
            "text": "Following our initial consultation, we assist you in selecting reliable suppliers for your 2-step dropshipping business on Walmart."
          },
          {
            "icon": "bi bi-cart-check",
            "heading": "Order Management",
            "text": "We help you streamline order management, ensuring that products are sourced and delivered efficiently to customers."
          },
          {
            "icon": "bi bi-person-check",
            "heading": "Review",
            "text": "Your input is crucial. We invite you to assess our 2-step dropshipping operations and share your thoughts. Together, we refine strategies for sustained growth."
          }
        ],
        "card_text": "Experience the power of 2-step dropshipping on Walmart. Our guidance and expertise help you build a business that stands out and thrives."
      },
      {
        "id": "etsy-dropshipping",
        "highlighted_title": "Effortless Dropshipping",
        "title": "Elevate your Etsy Shop with",
        "subheading": "Ready to take your Etsy store to new heights? Welcome to the world of Etsy Dropshipping, where we blend creativity and commerce for your e-commerce success.",
        "background_image": "/bg.png",
        "service_heading": "Our Etsy Dropshipping Services",
        "service_subheading": "Your Creative Companion",
        "service_img": "/services/etsy_dropshipping.jpg",
        "our_services": [
          {
            "heading": "Product Discovery",
            "desc": "We identify standout Etsy products that resonate with your niche."
          },
          {
            "heading": "Seamless Integration",
            "desc": "We make Etsy dropshipping a breeze, syncing your shop with your product lineup."
          },
          {
            "heading": "Listing Wizardry",
            "desc": "Elevate your Etsy listings with captivating descriptions and eye-catching visuals."
          },
          {
            "heading": "Inventory Mastery",
            "desc": "Stay in control with real-time inventory tracking."
          },
          {
            "heading": "Effortless Fulfillment",
            "desc": "Your orders are in safe hands, ensuring happy customers."
          },
          {
            "heading": "Growth Strategies",
            "desc": "We provide tactics to boost your Etsy shop's visibility and sales."
          }
        ],
        "why_us_heading": "Why Us? Your Partner in Creative Commerce",
        "choose_us": [
          {
            "para": "We're more than a service; we're your creative commerce partner. Our team is dedicated to helping your Etsy shop thrive in a competitive marketplace."
          },
          {
            "para": "Ready to explore the world of Etsy Dropshipping and infuse your shop with creativity and commerce? It all starts with one click—contact us now!"
          }
        ],
        "how_it_works": [
          {
            "icon": "bi bi-telephone",
            "heading": "Schedule a Free Consultation",
            "text": "We’ll answer any questions you have and, when you’re ready to proceed, you can choose the right package for your needs."
          },
          {
            "icon": "bi bi-gift",
            "heading": "Product Sourcing",
            "text": "After our initial conversation, we guide you in sourcing trending and profitable products for your Etsy dropshipping store."
          },
          {
            "icon": "bi bi-shop",
            "heading": "Store Setup and Promotion",
            "text": "We assist in setting up your Etsy dropshipping store and provide strategies for promoting your products effectively."
          },
          {
            "icon": "bi bi-person-check",
            "heading": "Review",
            "text": "Your review matters. Evaluate our Etsy dropshipping efforts and provide feedback. We collaborate on refining strategies to boost your success."
          }
        ],
        "card_text": "Craft your success story with Etsy dropshipping. Our strategies and support propel your store to extraordinary heights, where growth knows no limits."
      },
      {
        "id": "etsy-digital-store",
        "highlighted_title": "Digital Creativity",
        "title": "Elevate Your Etsy Store with",
        "subheading": "Ready to turn your passion for digital art into a thriving Etsy venture? Welcome to the world of Etsy Digital Stores, where we help your creativity shine in the digital realm.",
        "background_image": "/bg.png",
        "service_heading": "Our Etsy Digital Store Services",
        "service_subheading": "Your Digital Artisan Partner",
        "service_img": "/services/etsy_store.jpg",
        "our_services": [
          {
            "heading": "Creative Guidance",
            "desc": "We help you identify the digital products that resonate with your artistic vision."
          },
          {
            "heading": "Seamless Integration",
            "desc": "Effortlessly set up and manage your Etsy Digital Store, making your creations available to buyers."
          },
          {
            "heading": "Listing Brilliance",
            "desc": "Elevate your Etsy listings with compelling descriptions and captivating visuals."
          },
          {
            "heading": "Digital Delivery Mastery",
            "desc": "Ensure smooth and instant delivery of your digital products."
          },
          {
            "heading": "Marketing Insights",
            "desc": "We provide strategies to boost your Etsy store's visibility and sales."
          },
          {
            "heading": "Support for Artists",
            "desc": "Dedicated support to help artists like you thrive in the digital marketplace."
          }
        ],
        "why_us_heading": "Why Us? Your Partner in Digital Creativity",
        "choose_us": [
          {
            "para": "We're not just a service; we're your partner in artistic success. Our team is committed to helping your Etsy Digital Store flourish in the competitive digital art landscape."
          },
          {
            "para": "Ready to unleash your digital creativity and turn it into an Etsy success story? It all begins with one click—contact us now!"
          }
        ],
        "how_it_works": [
          {
            "icon": "bi bi-telephone",
            "heading": "Schedule a Free Consultation",
            "text": "We’ll answer any questions you have and, when you’re ready to proceed, you can choose the right package for your needs."
          },
          {
            "icon": "bi bi-file-earmark-code",
            "heading": "Digital Product Creation",
            "text": "Following our initial call, we help you create and curate digital products suitable for your Etsy store, ensuring they meet customer demand."
          },
          {
            "icon": "bi bi-megaphone",
            "heading": "Store Optimization and Marketing",
            "text": "We work with you to optimize your Etsy digital store and implement marketing techniques to attract buyers."
          },
          {
            "icon": "bi bi-person-check",
            "heading": "Review",
            "text": "Your insights are valuable. Assess your Etsy digital store's performance and discuss options for diversification. Your feedback shapes our approach."
          }
        ],
        "card_text": "Step into the spotlight with your Etsy digital store. We help you create a captivating presence, ensuring your store becomes a go-to destination for digital products."
      },
      {
        "id": "shopify-dropshipping",
        "highlighted_title": "Seamless Dropshipping",
        "title": "Supercharge Your Shopify Store with",
        "subheading": "Ready to turn your Shopify store into a retail powerhouse? Welcome to the world of Shopify Dropshipping, where we transform your e-commerce dreams into reality.",
        "background_image": "/bg.png",
        "service_heading": "Our Shopify Dropshipping Services",
        "service_subheading": "Your E-Commerce Partner",
        "service_img": "/services/shopify.jpg",
        "our_services": [
          {
            "heading": "Product Curation",
            "desc": "We identify winning products in your niche, ensuring they align with your brand."
          },
          {
            "heading": "Seamless Integration",
            "desc": "Effortlessly set up and manage your Shopify dropshipping operations."
          },
          {
            "heading": "Optimized Listings",
            "desc": "We craft compelling product listings that drive traffic and conversions."
          },
          {
            "heading": "Inventory Management",
            "desc": "Stay in control with real-time inventory tracking and stock updates."
          },
          {
            "heading": "Efficient Fulfillment",
            "desc": "We ensure your customers receive their orders promptly and accurately."
          },
          {
            "heading": "Marketing Strategies",
            "desc": "Our experts provide marketing tactics to boost your store's visibility and sales."
          }
        ],
        "why_us_heading": "Why Us? Your Partner in E-Commerce Success",
        "choose_us": [
          {
            "para": "We're not just a service; we're your partner in e-commerce success. Our team is dedicated to helping your Shopify store thrive in a competitive marketplace."
          },
          {
            "para": "Ready to supercharge your Shopify store with dropshipping? Your journey to e-commerce success starts here—contact us now!"
          }
        ],
        "how_it_works": [
          {
            "icon": "bi bi-telephone",
            "heading": "Schedule a Free Consultation",
            "text": "We’ll answer any questions you have and, when you’re ready to proceed, you can choose the right package for your needs."
          },
          {
            "icon": "bi bi-shop-window",
            "heading": "Store Setup and Product Selection",
            "text": "After our initial conversation, we assist in setting up your Shopify dropshipping store and selecting products that align with your niche."
          },
          {
            "icon": "bi bi-megaphone",
            "heading": "Marketing and Customer Engagement",
            "text": "We provide guidance on marketing strategies and customer engagement techniques to drive traffic and sales to your Shopify store."
          },
          {
            "icon": "bi bi-person-check",
            "heading": "Review",
            "text": "We value your feedback. Review your Shopify dropshipping store's performance and let's strategize for scaling and continued growth."
          }
        ],
        "card_text": "Scale new heights with your Shopify dropshipping store. Our tailored approach and insights pave the way for an extraordinary journey filled with achievements."
      }
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
