var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar sticky-top navbar-expand-lg",attrs:{"data-bs-theme":"dark"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/blinker-logo.png"),"alt":"Blinkers Marketing","width":"70"}})]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/"}},[_c('h1',{staticClass:"text-white mb-0 font-bold fs-3"},[_vm._v("Blinkers")])])],1),_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ms-auto mb-2 mb-lg-0 font-regular"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link me-2",class:{ active: _vm.$route.path === '/' },attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"nav-item dropdown"},[_c('router-link',{staticClass:"nav-link me-2 dropdown-toggle",class:{ active: _vm.$route.path.includes('/service/') },attrs:{"to":"/services","role":"button","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_vm._v(" Services ")]),_c('ul',{staticClass:"dropdown-menu"},[_c('li',[_c('router-link',{staticClass:"dropdown-item",class:{
                  active:
                    _vm.$route.path === '/service/amazon-private-label' ||
                    _vm.$route.path === '/service/amazon-private-label/',
                },attrs:{"to":'/service/amazon-private-label'}},[_vm._v("Amazon Private Label")])],1),_c('li',[_c('router-link',{staticClass:"dropdown-item",class:{
                  active:
                    _vm.$route.path === '/service/amazon-online-arbitrage' ||
                    _vm.$route.path === '/service/amazon-online-arbitrage/',
                },attrs:{"to":'/service/amazon-online-arbitrage'}},[_vm._v("Amazon Online Arbitrage")])],1),_c('li',[_c('router-link',{staticClass:"dropdown-item",class:{
                  active:
                    _vm.$route.path === '/service/walmart-wfs' ||
                    _vm.$route.path === '/service/walmart-wfs/',
                },attrs:{"to":'/service/walmart-wfs'}},[_vm._v("Walmart WFS")])],1),_c('li',[_c('router-link',{staticClass:"dropdown-item",class:{
                  active:
                    _vm.$route.path === '/service/walmart-dropshipping' ||
                    _vm.$route.path === '/service/walmart-dropshipping/',
                },attrs:{"to":'/service/walmart-dropshipping'}},[_vm._v("Walmart 2-Step Dropshipping")])],1),_c('li',[_c('router-link',{staticClass:"dropdown-item",class:{
                  active:
                    _vm.$route.path === '/service/etsy-dropshipping' ||
                    _vm.$route.path === '/service/etsy-dropshipping/',
                },attrs:{"to":'/service/etsy-dropshipping'}},[_vm._v("Etsy Dropshipping")])],1),_c('li',[_c('router-link',{staticClass:"dropdown-item",class:{
                  active:
                    _vm.$route.path === '/service/etsy-digital-store' ||
                    _vm.$route.path === '/service/etsy-digital-store/',
                },attrs:{"to":'/service/etsy-digital-store'}},[_vm._v("Etsy Digital Store")])],1),_c('li',[_c('router-link',{staticClass:"dropdown-item",class:{
                  active:
                    _vm.$route.path === '/service/shopify-dropshipping' ||
                    _vm.$route.path === '/service/shopify-dropshipping/',
                },attrs:{"to":'/service/shopify-dropshipping'}},[_vm._v("Shopify Dropshipping")])],1),_c('li',[_c('router-link',{staticClass:"dropdown-item",class:{
                  active:
                    _vm.$route.path === '/service/amazon-automation' ||
                    _vm.$route.path === '/service/amazon-automation/',
                },attrs:{"to":'/service/amazon-automation'}},[_vm._v("Amazon Automation")])],1)])],1),_c('li',{staticClass:"nav-item dropdown"},[_c('router-link',{staticClass:"nav-link me-2 dropdown-toggle",class:{ active: _vm.$route.path.includes('/case-studies/') },attrs:{"to":"/case-studies","role":"button","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_vm._v(" Case Studies ")]),_c('ul',{staticClass:"dropdown-menu"},[_vm._l((_vm.caseStudyRoutes),function(route,index){return _c('li',{key:index},[_c('router-link',{staticClass:"dropdown-item",class:{
                  active:
                    _vm.$route.path === `/case-studies/${route.path}` ||
                    _vm.$route.path === `/case-studies/${route.path}`,
                },attrs:{"to":`/case-studies/${route.path}`}},[_vm._v(_vm._s(route.name))])],1)}),(!_vm.caseStudyRoutes)?_c('div',{staticClass:"text-center"},[_vm._m(1)]):_vm._e()],2)],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link me-2",class:{
              active:
                _vm.$route.path === '/portfolio' || _vm.$route.path === '/portfolio/',
            },attrs:{"to":"/portfolio"}},[_vm._v("Portfolio")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link me-2",class:{
              active: _vm.$route.path === '/about' || _vm.$route.path === '/about/',
            },attrs:{"to":"/about"}},[_vm._v("About Us")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link me-2",class:{
              active:
                _vm.$route.path === '/contact' || _vm.$route.path === '/contact/',
            },attrs:{"to":"/contact"}},[_vm._v("Contact Us")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"btn btn-primary px-4 font-bold",attrs:{"to":"/contact"}},[_vm._v("Lets Start →")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }