import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBztX7P3vd9PF4pqgGIvfgD_2sXVlY8VmU",
  authDomain: "blinkers-6f866.firebaseapp.com",
  projectId: "blinkers-6f866",
  storageBucket: "blinkers-6f866.appspot.com",
  messagingSenderId: "922489715137",
  appId: "1:922489715137:web:6cadbbac03fd24f58f9740"
};


initializeApp(firebaseConfig);

const db = getFirestore();

export default db;