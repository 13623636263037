import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "./assets/css/scss/app.scss";
import "animate.css";

Vue.config.productionTip = false;

// ANIMATION ON SCROLL UP AND DOWN
// function animateOnScroll() {
//   const elements = document.querySelectorAll(".animation"); 
//   elements.forEach((element) => {
//     const elementPosition = element.getBoundingClientRect();
//     const windowHeight = window.innerHeight;
//     const animationClass = element.getAttribute('data-animation');

//     if (elementPosition.top < windowHeight && elementPosition.bottom > 0) {
//       element.classList.add("animate__animated", `animate__${animationClass}`); 
//     } else {
//       element.classList.remove("animate__animated", `animate__${animationClass}`);
//     }
//   });
// }

// ANIMATION ON ONLY SCROLL DOWN FOR ONCE
function animateOnScroll() {
  const elements = document.querySelectorAll(".animation");

  const scrollY = window.scrollY || window.pageYOffset;
  const scrollDirection = scrollY > animateOnScroll.lastScrollY ? 'down' : 'up';
  animateOnScroll.lastScrollY = scrollY;

  elements.forEach((element) => {
    const elementPosition = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const animationClass = element.getAttribute('data-animation');
    const hasBeenAnimated = element.classList.contains("animate__animated");

    if (elementPosition.top < windowHeight && elementPosition.bottom > 0 && scrollDirection === 'down' && !hasBeenAnimated) {
      element.classList.add("animate__animated", `animate__${animationClass}`);
      element.classList.add("has-been-animated"); // Add a class to mark the element as animated
    }
  });
}


new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    window.addEventListener("scroll", animateOnScroll);
  },
  mounted(){
    window.onload = function () {
      window.scrollTo(0, 0);
    }
    animateOnScroll.lastScrollY = 0;
  }
}).$mount("#app");
