<template>
  <nav class="navbar sticky-top navbar-expand-lg" data-bs-theme="dark">
    <div class="container">
      <div class="d-flex align-items-center">
        <router-link to="/">
          <img
            src="@/assets/img/blinker-logo.png"
            alt="Blinkers Marketing"
            width="70"
            class="img-fluid"
          />
        </router-link>
        <router-link to="/" class="text-decoration-none">
          <h1 class="text-white mb-0 font-bold fs-3">Blinkers</h1>
        </router-link>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 font-regular">
          <li class="nav-item">
            <router-link
              class="nav-link me-2"
              :class="{ active: $route.path === '/' }"
              to="/"
              >Home</router-link
            >
          </li>
          <li class="nav-item dropdown">
            <router-link
              class="nav-link me-2 dropdown-toggle"
              :class="{ active: $route.path.includes('/service/') }"
              to="/services"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Services
            </router-link>
            <ul class="dropdown-menu">
              <li>
                <router-link
                  class="dropdown-item"
                  :class="{
                    active:
                      $route.path === '/service/amazon-private-label' ||
                      $route.path === '/service/amazon-private-label/',
                  }"
                  :to="'/service/amazon-private-label'"
                  >Amazon Private Label</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="{
                    active:
                      $route.path === '/service/amazon-online-arbitrage' ||
                      $route.path === '/service/amazon-online-arbitrage/',
                  }"
                  :to="'/service/amazon-online-arbitrage'"
                  >Amazon Online Arbitrage</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="{
                    active:
                      $route.path === '/service/walmart-wfs' ||
                      $route.path === '/service/walmart-wfs/',
                  }"
                  :to="'/service/walmart-wfs'"
                  >Walmart WFS</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="{
                    active:
                      $route.path === '/service/walmart-dropshipping' ||
                      $route.path === '/service/walmart-dropshipping/',
                  }"
                  :to="'/service/walmart-dropshipping'"
                  >Walmart 2-Step Dropshipping</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="{
                    active:
                      $route.path === '/service/etsy-dropshipping' ||
                      $route.path === '/service/etsy-dropshipping/',
                  }"
                  :to="'/service/etsy-dropshipping'"
                  >Etsy Dropshipping</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="{
                    active:
                      $route.path === '/service/etsy-digital-store' ||
                      $route.path === '/service/etsy-digital-store/',
                  }"
                  :to="'/service/etsy-digital-store'"
                  >Etsy Digital Store</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="{
                    active:
                      $route.path === '/service/shopify-dropshipping' ||
                      $route.path === '/service/shopify-dropshipping/',
                  }"
                  :to="'/service/shopify-dropshipping'"
                  >Shopify Dropshipping</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="{
                    active:
                      $route.path === '/service/amazon-automation' ||
                      $route.path === '/service/amazon-automation/',
                  }"
                  :to="'/service/amazon-automation'"
                  >Amazon Automation</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <router-link
              class="nav-link me-2 dropdown-toggle"
              :class="{ active: $route.path.includes('/case-studies/') }"
              to="/case-studies"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Case Studies
            </router-link>
            <ul class="dropdown-menu">
              <li v-for="(route, index) in caseStudyRoutes" :key="index">
                <router-link
                  class="dropdown-item"
                  :class="{
                    active:
                      $route.path === `/case-studies/${route.path}` ||
                      $route.path === `/case-studies/${route.path}`,
                  }"
                  :to="`/case-studies/${route.path}`"
                  >{{ route.name }}</router-link
                >
              </li>
              <div class="text-center" v-if="!caseStudyRoutes">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </ul>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link me-2"
              :class="{
                active:
                  $route.path === '/portfolio' || $route.path === '/portfolio/',
              }"
              to="/portfolio"
              >Portfolio</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link me-2"
              :class="{
                active: $route.path === '/about' || $route.path === '/about/',
              }"
              to="/about"
              >About Us</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link me-2"
              :class="{
                active:
                  $route.path === '/contact' || $route.path === '/contact/',
              }"
              to="/contact"
              >Contact Us</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="btn btn-primary px-4 font-bold" to="/contact"
              >Lets Start &rarr;</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import db from "@/firebase";
import { getDoc, doc } from "firebase/firestore";
export default {
  name: "Navbar",
  data() {
    return {
      caseStudyRoutes: null,
    };
  },
  created() {
    const docRef = doc(db, "caseStudies", "routes");
    getDoc(docRef)
      .then((doc) => {
        this.caseStudyRoutes = doc.data().routes;
      })
      .catch((error) => {
        console.error("Error", error);
      });
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  background-color: #252324;
}
.font-bold {
  font-family: "Ubuntu-Bold";
}
.font-medium {
  font-family: "Ubuntu-Medium";
}
.font-regular {
  font-family: "Ubuntu-Regular";
}
.font-light {
  font-family: "Ubuntu-Light";
}
</style>
